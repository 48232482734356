import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import { Backdrop, Paper, Typography } from '@mui/material';

import * as AppErrorStyles from './AppError.styles';
import ErrorNotice from '../ErrorNotice';

export interface AppErrorProps {
  eventId?: string;
}

function AppError({ eventId }: AppErrorProps) {
  const { classes: wrapperClasses } = AppErrorStyles.useWrapper();
  const { classes: contentsClasses } = AppErrorStyles.useContents();
  const { classes: backdropClasses } = AppErrorStyles.useBackdrop();
  const { classes: titleClasses } = AppErrorStyles.useTitle();
  const { classes: alertClasses } = AppErrorStyles.useAlert();
  const { classes: eventIdClasses } = AppErrorStyles.useEventId();

  return (
    <Backdrop open classes={backdropClasses}>
      <Paper classes={wrapperClasses}>
        <Typography classes={titleClasses} align="center" gutterBottom variant="h5">
          Something went wrong!
        </Typography>
        <Box className={contentsClasses.root}>
          <ErrorNotice />
          {!!eventId && (
            <Alert classes={alertClasses} severity="error">
              <AlertTitle>Error</AlertTitle>
              Please include this event ID when reporting an error:{' '}
              <strong className={eventIdClasses.root}>{eventId}</strong>
            </Alert>
          )}
        </Box>
      </Paper>
    </Backdrop>
  );
}

export default AppError;
