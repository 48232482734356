import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useBackdrop = makeStyles()({
  root: {
    zIndex: 1,
  },
});

export const useWrapper = makeStyles()(({ palette, breakpoints, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.secondary.lighter,
    paddingBottom: spacing(1),
    overflow: 'hidden',
    [breakpoints.up('sm')]: {
      maxWidth: 460,
    },
    [breakpoints.down('md')]: {
      margin: spacing(2),
    },
  },
}));

export const useContents = makeStyles()(({ spacing }: Theme) => ({
  root: {
    padding: spacing(2),
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    justifyContent: 'center',
  },
}));

export const useTitle = makeStyles()(({ palette, spacing }: Theme) => ({
  root: {
    backgroundColor: palette.primary.main,
    color: palette.common.white,
    padding: spacing(2.5, 3, 2),
  },
}));

export const useAlert = makeStyles()(({ spacing }: Theme) => ({
  root: {
    marginTop: spacing(2),
  },
}));

export const useEventId = makeStyles()(() => ({
  root: {
    overflowWrap: 'anywhere',
  },
}));
